
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import {
  IModel,
  ILabelContainerUpdate,
  IModelCreate,
  ILabelContainerUpdateUtteranceSpan,
} from "@/interfaces";
import { readModel, readModels, readFirstLabelContainer, readLabels } from "@/store/model/getters";
import {
  dispatchUpdateLabelContainer,
  dispatchClearRecommendation,
  dispatchUpdateModel,
  dispatchUpdateLabelContainerUtteranceSpan,
} from "@/store/model/actions";

import { readUserProfile, readHasAdminAccess } from "@/store/main/getters";

import ChangeLabelsCard from "@/components/ChangeLabelsCard.vue";
import { readToken } from "@/store/main/getters";
import { api } from "@/api";

@Component({
  components: {
    ChangeLabelsCard,
  }
})

export default class CreateLabelContainer extends Vue {
  public id: number = 1;
  public labelContainerName: string = "";
  public modelName: string = "";
  public modelDescription: string = "";
  public dialogLabelContainer: boolean = true;
  public loading: boolean = false;
  public trainWithContext: boolean = false;
  public tab: any = null;
  public modelNameError: string = "";
  public labelContainerNameError: string = "";
  public previousUtterances: number = 0;
  public followingUtterances: number = 0;
  public selectedColor: string | null = null;
  public loadingColor: boolean = false;
  public treeData: any = [];
  public loadingTreeData: boolean = false;
  public treeItems: any = [];
  public selectedModelId: number | null = null;
  public openItems: any = [];

  public colors: string[] = [
    "purple",
    "pink",
    "deep-purple",
    "red",
    "indigo",
    "blue",
    "light-blue",
    "cyan",
    "teal",
    "green",
    "light-green",
    "lime",
    "yellow",
    "amber",
    "orange",
    "deep-orange",
    "brown",
    "blue-grey",
    "grey",
  ];

  public handleRadioClick(id: number) {
      // Set the radio button value directly
      this.selectedModelId = id;
    }


  public async getTreeData() {
    this.loadingTreeData = true;
    await api
      .getTreeData(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
        false,
      )
      .then((r) => {
        this.treeData = r.data;
        console.log("TreeData", this.treeData);
        this.loadingTreeData = false;
      })
      .catch((e) => {
        console.log("Error", e);
        this.loadingTreeData = false;
      });
  }

  public parseDataTree(dataNode: any, parent: any = null) {
    const { model, children } = dataNode;
    const isDisabled = !!model.algorithm;  // Disable if 'algorithm' exists
    const item: any = {
      names: model.name,
      children: isDisabled ? [] : children.map((child: any) => this.parseDataTree(child, true)),
      disabled: isDisabled,
      id: model.id,
      isRoot: parent === null,
    };
    if (isDisabled) {
      console.log("children", dataNode)
    }
    
    if (parent === null) {
      return [item];
    } else {
      return item
    }
  }

  public onSelectModel(selectedItems: any) {
      const selectedItem = selectedItems.length ? selectedItems[0] : null;

      if (selectedItem && !selectedItem.disabled) {
        this.selectedModelId = selectedItem.id;
        console.log(`Selected model ID: ${this.selectedModelId}`);
      } else {
        // Reset selection if disabled
        this.selectedModelId = null;
      }
    }

  get colorOptions() {

    const colors = [
          { name: 'No color', value: null },
          ...this.colors.map(color => ({ name: color, value: color })),
        ];
    
    if (this.user!.is_superuser) {
      colors.push({ name: 'black', value: 'black' });
    }
    return colors;
  }

  get token() {
    return readToken(this.$store);
  }


  public async newColor(data) {
    console.log(data);
    this.loadingColor = true;
    await api
      .changeColor(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        this.model!.id,
        data.value)
      .then((res) => {
        this.loadingColor = false;
        this.selectedColor = data.value;
      })
      .catch((error) => {
        console.log("ERROR", error.response)
        this.loadingColor = false;
      });
  }

  get user() {
    return readUserProfile(this.$store);
  }

  get isAdmin() {
    return readHasAdminAccess;
  }

  public async mounted() {
    await this.getTreeData();
    this.treeItems = this.parseDataTree(this.treeData);
    console.log("TreeData", this.treeData);

    if (this.firstLabelContainer!.name !== "null") {
      this.labelContainerName = this.firstLabelContainer!.name;
    }
    if (this.firstLabelContainer!.previous_utterances !== null) {
      this.previousUtterances = this.firstLabelContainer!.previous_utterances;
    }
    if (this.firstLabelContainer!.following_utterances !== null) {
      this.followingUtterances = this.firstLabelContainer!.following_utterances;
    }
    if (this.firstLabelContainer!.train_with_context !== null) {
      this.trainWithContext = this.firstLabelContainer!.train_with_context;
    }
    this.modelName = this.model ? this.model.name : "";
    this.modelDescription = this.model ? this.model.description : "";
    this.selectedColor = this.model ? this.model.color : null;
  }

  get woot() {
    return "woot";
  }

  get model() {
    return readModel(this.$store)(+this.$router.currentRoute.params.id);
  }

  get models() {
    return readModels(this.$store);
  }

  get firstLabelContainer() {
    return readFirstLabelContainer(this.$store)(+this.$router.currentRoute.params.id);
  }
  // ==================MODEL NAME===========
  get modelNameExists(): boolean {
    if (this.modelName === this.model!.name) {
      return false;
    }
    return this.models.filter((model) => model.name === this.modelName).length > 0;
  }

  get modelNameErrors(): string[] {
    if (this.modelNameExists) {
      return ["This name does already exist"];
    }
    if (this.modelName.length && (this.modelName.length < 1 || this.modelName.length > 40)) {
      return ["Please provide a name between 1 and 40 characters long"];
    }
    return [];
  }

  get modelIsClean(): boolean {
    if (this.modelName.length < 1 || this.modelName.length > 40) {
      return false;
    }
    if (this.modelNameExists) {
      return false;
    }

    return true;
  }
  // ==========================================

  get labelContainerNameExists(): boolean {
    return false;
  }

  get labels() {
    return readLabels(this.$store)(+this.$router.currentRoute.params.id);
  }

  get labelContainerNameErrors(): string[] {
    if (this.labelContainerNameExists) {
      return ["This name does already exist"];
    }
    return [];
  }

  get labelContainerIsClean(): boolean {
    if (this.labelContainerName.length < 1 || this.labelContainerName.length > 80) {
      return false;
    }
    if (this.labelContainerNameExists) {
      return false;
    }
    return true;
  }

  public updateLabelContainerAPI() {
    this.labelContainerNameError = "";
    const labelContainer: ILabelContainerUpdate = {
      name: this.labelContainerName,
      type: null,
    };
    dispatchUpdateLabelContainer(this.$store, {
      modelId: this.model!.id,
      labelContainerId: parseInt(this.$router.currentRoute.params.labelcontainerid, 10),
      labelContainer,
    })
      .then((r) => {
        // this.$router.push({
        //   path: `/main/${this.$router.currentRoute.params.workspaceid}
        // /classification/${this.$router.currentRoute.params.id}/dashboard/dashboard`,
        // });
        // this.labelContainerName = "";
      })
      .catch((err) => {
        this.labelContainerNameError = err.response;
      });
  }

  public updateUtteranceSpan() {
    this.labelContainerNameError = "";
    const labelContainer: ILabelContainerUpdateUtteranceSpan = {
      previous_utterances: this.previousUtterances,
      following_utterances: this.followingUtterances,
      train_with_context: this.trainWithContext,
    };
    dispatchUpdateLabelContainerUtteranceSpan(this.$store, {
      modelId: this.model!.id,
      labelContainerId: parseInt(this.$router.currentRoute.params.labelcontainerid, 10),
      labelContainer,
    })
      .then((r) => {
        dispatchClearRecommendation(this.$store);
        // this.$router.push({
        //   path: `/main/${this.$router.currentRoute.params.workspaceid}
        // /classification/${this.$router.currentRoute.params.id}/dashboard/dashboard`,
        // });
        // this.labelContainerName = "";
      })
      .catch((err) => {
        this.labelContainerNameError = err.response;
      });
  }

  public updateModelName() {
    this.modelNameError = "";
    const model: IModelCreate = {
      name: this.modelName,
      description: this.modelDescription,
    };
    dispatchUpdateModel(this.$store, {
      workspaceId: parseInt(this.$router.currentRoute.params.workspaceid, 10),
      modelId: this.model!.id,
      model,
    })
      .then((r) => {
        // this.$router.push({
        //   path: `/main/${this.$router.currentRoute.params.workspaceid}
        // /classification/${this.$router.currentRoute.params.id}/dashboard`,
        // });
      })
      .catch((err) => {
        this.modelNameError = err.response;
      });
  }
}
